<template>
  <div id="shareShop">
    <div id="poster" ref="imageWrapper" v-if="imgShow">
      <img class="bg" src="@images/poster-bg.png" alt />
      <img :src="shopInfo.store_qrcode" alt class="code" />
      <p class="tips">长按二维码识别进店</p>
      <div class="info-box">
        <img :src="shopInfo.market_logo" alt />
        <div>
          <p>{{shopInfo.market_name}}</p>
          <span>{{shopInfo.address}}</span>
        </div>
      </div>
    </div>

    <img class="poster-pic" :src="imgUrl" alt v-if="!imgShow" crossorigin="Anonymous" />

    <div class="btn-box">
      <div class="share-btn" @click="shareTip = true">分享</div>
      <div class="keep-btn" @click="keep">保存</div>
    </div>

    <div class="share-tip-box" v-if="shareTip" @click="shareTip = false">
      <img class="tip" src="@images/share-tip.png" alt />
      <img class="btn" src="@images/share-btn.png" alt />
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import html2canvas from "html2canvas";
import { shareInfo } from "../api/requist";
import wxShare from "../common/js/wxShare";
export default {
  components: {},
  props: [],
  data() {
    return {
      imgShow: true,
      shareTip: false,
      imgUrl: "",
      shopInfo: {}
    };
  },
  created() {
    if (!sessionStorage.getItem('reload')) {
      sessionStorage.setItem('reload', true);
      this.$router.go(0)
    }
  },
  mounted() {
    this.getInfo();
  },
  watch: {},
  computed: {},
  methods: {
    async getInfo() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem("merch_id"));
      let res = await shareInfo(formData);
      if (res.code === 200) {
        this.shopInfo = res.data;
        wxShare.shareCommon(
          this.shopInfo.market_name,
          this.shopInfo.market_introduction,
          this.shopInfo.market_logo,
          "https://view.xitiger.com/index?merch_sn=" + this.shopInfo.merch_sn
        );
        this.$nextTick(() => {
          this.drawPic();
        });
      }
    },

    drawPic() {
      html2canvas(this.$refs.imageWrapper, {
        backgroundColor: "#F5F7FA", //无背景
        useCORS: true
      }).then(canvas => {
        let dataURL = canvas.toDataURL("image/png");
        this.imgUrl = dataURL;
        this.$nextTick(() => {
          this.imgShow = false;
          Toast.clear();
          Toast("海报生成成功");
        });
      }).catch(() => {
      });
    },
    keep() {
      Dialog.alert({
        message: "长按海报保存至相册",
        confirmButtonText: "我知道了",
        confirmButtonColor: "#FF6737"
      }).then(() => {
        // on close
      });
    }
  }
};
</script>
<style lang="scss" >
@import "../common/css/theme.scss";
#shareShop {
  min-height: 100vh;
  width: 100%;
  background: rgba(245, 247, 250, 1);
  .poster-pic {
    width: 100%;
    height: 153.07* 3.75px;
  }
  #poster {
    width: 100%;
    height: 153.07* 3.75px;
    position: relative;
    padding-top: 41.8* 3.75px;
    box-sizing: border-box;
    .code {
      border-radius: 1* 3.75px;
      margin: 0 auto 5.07* 3.75px;
      display: block;
      position: relative;
      height: 43* 3.75px;
      width: 43* 3.75px;
    }
    .bg {
      width: 100%;
      height: 153.07* 3.75px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .tips {
      position: relative;
      color: rgba(255, 255, 255, 1);
      font-size: 3.73* 3.75px;
      text-align: center;
    }

    .info-box {
      padding-left: 22.67* 3.75px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      margin-top: 16.27* 3.75px;
      align-items: center;
      img {
        position: relative;
        height: 12.8* 3.75px;
        width: 12.8* 3.75px;
        border-radius: 50%;
        margin-right: 1.87* 3.75px;
      }

      p {
        font-size: 4.8* 3.75px;
        position: relative;
        font-weight: bold;
        color: rgba(48, 51, 48, 1);
        margin-bottom: 1.07* 3.75px;
        text-align: left;
      }

      span {
        position: relative;
        color: rgba(96, 102, 99, 1);
        font-size: 3.2* 3.75px;
        display: block;
        text-align: left;
      }
    }
  }

  .share-tip-box {
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 2.67* 3.75px;
    box-sizing: border-box;
    .tip {
      margin: 0 5.07* 3.75px 0 auto;
      width: 72.22* 3.75px;
      height: 97.44* 3.75px;
      display: block;
    }

    .btn {
      display: block;
      margin: 36.8* 3.75px auto 0;
      width: 30.4* 3.75px;
      height: 10.67* 3.75px;
    }
  }

  .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .share-btn {
      margin-right: 10.93* 3.75px;
    }
    .share-btn,
    .keep-btn {
      width: 32* 3.75px;
      height: 9.6* 3.75px;
      background: linear-gradient(
        360deg,
        $wallte-color 0%,
        $theme-color 100%
      );
      border-radius: 5* 3.75px;
      font-size: 4.27* 3.75px;
      line-height: 9.6* 3.75px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
